

body > .container-fluid {
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    position: relative;
    box-shadow: 0 6px 20px rgba(151,158,160,.1);
    margin: 0 30px;
    width: calc(100% - 60px);
}

.dropdown-menu .dropdown-item{
    border-radius: 0;
    color: #16181b !important;
}
.dropdown-menu .dropdown-item:active{
    background: #35a09c !important;
    color: white !important;
    color: #16181b !important;
}

button.navbar-toggler {
    filter: invert(100%);
    border: none
}



li.nav-item.border.border-dark.nav-item.p-2.rounded.mr-2.btn.bg-white {
    font-size: 13px;
    line-height: 40px;
    vertical-align: middle;
    padding: 0 20px !important;
    margin-right: 20px !important;
    border:none !important;
}



input[name=dates] {
    border: none;
    font-size: 13px;
    text-align: center;
    background: #f4f6f9;
    cursor: pointer;
}

input[name=dates]:focus {
    box-shadow: none;
}




th {
    cursor: pointer;
}

.custom-button {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: .5;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}
.modal-backdrop {
    width: 100%;
    height: 100%;
}
.fade {
    transition: opacity .15s linear;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}
.fade {
    transition: opacity .15s linear;
}

.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
}

/* .modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0.3rem;
} */

.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
}

.modal.fade .modal-dialog {
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out,-webkit-transform .3s ease-out;
    -webkit-transform: translate(0,-50px);
    transform: translate(0,-50px);
}


.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
}
.modal-dialog-centered {
    @media (min-width: 576px) {
        min-height: calc(100% - (1.75rem * 2));
    }
}
.modal-dialog {
    @media (min-width: 576px) {
        max-width: 500px;
        margin: 1.75rem auto;
    }
}
.modal-dialog-centered {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (0.5rem * 2));
}
.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

.popover {
    max-width: 400px;
}

.popover .notification {
    padding: 5px !important;
    border: none !important;
}

table {
    font-size: 12px;
}





input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.number-input {
    border: 2px solid #ddd;
    display: inline-flex;
}

.number-input,
.number-input * {
    box-sizing: border-box;
    color: #212529;
}

.number-input button {
    outline: none;
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 25px;
    cursor: pointer;
    margin: 0;
    position: relative;
}

.number-input button:before,
.number-input button:after {
    display: inline-block;
    position: absolute;
    content: '';
    width: 10px;
    height: 2px;
    background-color: #212121;
    transform: translate(-50%, -50%);
}
.number-input button.plus:after {
    transform: translate(-50%, -50%) rotate(90deg);
}

.number-input input[type=number] {
    font-family: sans-serif;
    width: 50px;
    padding: .5rem;
    border: solid #ddd;
    border-width: 0 2px;
    font-size: 12px;
    height: 25px;
    text-align: center;
    transition: all 0.2s;
}

.campaigns-table {
    min-height: 400px;
}

.modal-header
{
    background-color: #2f373c;
    color:white;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem
}

.close, .close:hover {
    color: white;
}

.verticals {
    column-count: 2;
    column-gap: 15px;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    font-size: 12px;
}

.verticals input
{
    margin-right: 5px;
}