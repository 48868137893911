
/*Zoom out Platform
html {
  /*zoom: .95;
}*/

/*Zoom in Intercom
#intercom-positioner-tree, #intercom-container {
  zoom: 1.05;
}*/

body > .container-fluid {
  background: #fff;
  padding: 25px 25px 90px;
  /*border-radius: 4px;*/
  position: relative;
  /*box-shadow: 0 6px 10px rgba(151, 158, 160, .1);*/
  /*margin: 0px 20px;*/
  /*width: calc(100% - 40px);*/
}

.modal-header {
  background-color: #2f373c;
  color: white;
}

.close, .close:hover {
  color: white;
}


/* NAVBAR */


@media (min-width: 1500px) {
  nav.navbar-expand-extra {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  nav.navbar-expand-extra .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-expand-extra .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-extra .navbar-toggler {
    display: none;
  }

  .navbar-expand-extra .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }

  .navbar-expand-extra .navbar-nav .dropdown-menu {
    position: absolute;
  }
}

.btn.btn-primary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #3374F6;
  border-radius: 10px;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  padding: 6px 20px;
  transition: .4s;
}


.btn.btn-primary:hover:not(:disabled) {
  background-color: #2657ba;
}

.btn.btn-primary:disabled {
  cursor: not-allowed;
  opacity: 0.55;
}

button.navbar-toggler {
  filter: invert(100%);
  border: none
}

li.nav-item.border.border-dark.nav-item.p-2.rounded.mr-2.btn.bg-white {
  font-size: 13px;
  line-height: 40px;
  display: flex;
  align-items: center;
  padding: 0 5px !important;
  margin: 0 5px 0 5px !important;
  border: 1px solid white !important;
  background: transparent !important;
}

li.nav-item.border.border-dark.nav-item.p-2.rounded.mr-2.btn.bg-white > a {
  color: white !important;
}

li.nav-item.border.border-dark.nav-item.p-2.rounded.mr-2.btn.bg-warning {
  font-size: 13px;
  padding: 10px 20px !important;
  margin-right: 20px !important;
  border: 1px solid #ffc107 !important;
  background: transparent !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  white-space: nowrap;
}

li.nav-item.border.border-dark.nav-item.p-2.rounded.mr-2.btn.bg-warning > a {
  color: #ffc107 !important;
}


/* TABLES */

table {
  font-size: 12px;
}


th {
  cursor: pointer;
}


.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f4f6f9;
}

.table-hover tbody tr:hover {
  background-color: #e6e8eb;
}

.campaigns-table thead th {
  word-wrap: normal;
  word-break: keep-all;
  padding: 5px 10px 5px 10px;
}

.campaigns-table tbody td {
  text-align: left;
  padding: 10px;
}

.campaigns-table tfoot td {
  padding: 5px 10px 5px 10px;
}


/* BODY SELECTORS AND INPUTS */

.custom-button {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  border: none !important;
  background: #f4f6f9;
}

.dropdown-menu .dropdown-item {
  border-radius: 0;
  color: #16181b !important;
}

.dropdown-menu .dropdown-item:active {
  background: #ebebeb !important;
  color: white !important;
  color: #16181b !important;
}

.filter-option-inner-inner:hover {
  color: white !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}


input[name=dates] {
  border: none;
  font-size: 13px;
  text-align: center;
  background: #f4f6f9;
  cursor: pointer;
  background: #fff;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #ccc;
  width: 100%;
}

input[name=dates]:focus {
  box-shadow: none;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.popover {
  max-width: 400px;
}

.popover .notification {
  padding: 5px !important;
  border: none !important;
}

.number-input {
  border: 2px solid #ddd;
  display: inline-flex;
}

.number-input,
.number-input * {
  box-sizing: border-box;
  color: #212529;
}

.number-input button {
  outline: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 25px;
  cursor: pointer;
  margin: 0;
  position: relative;
}

/*
.number-input button:before,
.number-input button:after {
    display: inline-block;
    position: absolute;
    content: '';
    width: 10px;
    height: 2px;
    background-color: #212121;
    transform: translate(-50%, -50%);
}

.number-input button.plus:after {
    transform: translate(-50%, -50%) rotate(90deg);
}
*/

.number-input input[type=number] {
  font-family: sans-serif;
  width: 50px;
  padding: .5rem;
  border: solid #ddd;
  border-width: 0 1px;
  font-size: 12px;
  height: 25px;
  text-align: center;
  transition: all 0.2s;
}

.custom-selector-filter {
  background: #3374F6;
  color: white;
  font-weight: bold;
  font-size: small;
  border-radius: 5px 0 0 5px;
  height: 38px;
}

.custom-selector {
  border-radius: 0 5px 5px 0;
}

.dropdown-item.active .dropdown-item:active .selected .active {
  background: rgba(35, 35, 35, 0.53) !important;
  color: white !important;
}

#campaigns_filter_select {
  background: #3374F6;
  color: white;
}

.swal-button--apply {
  background: #3374F6;
}

.badge {
  background: #3374F6;
}

.card {
  max-width: 400px;
}

.card-large {
  min-height: 190px;
}

.card-body {
  padding: 15px 10px 15px 10px;
}

.card-title {
  font-weight: bold;
}

.card-text {
  font-size: 13px;
  margin-bottom: 5px;
}

.card-code {
  max-width: 150px;
}

.card-code input {
  font-size: small;
  height: 25px;
  border-right: 0;
  border-radius: 5px 0 0 5px;
}

.card-code button {
  font-size: small;
  height: 25px;
  padding: 2px 7px 0 7px;
  border-radius: 0 5px 5px 0;
}

.card-code-redeemed {
  width: 100px;
  margin-top: 10px;
  font-size: small;
  height: 25px;
  padding: 2px 7px 2px 7px !important;
  color: #40b473;
  float: right;

  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  background-color: transparent;
  border: 1px solid grey;
  line-height: 1.5;
  border-radius: .25rem;
}

.card-code-expired {
  width: 100px;
  margin-top: 10px;
  font-size: small;
  height: 25px;
  padding: 2px 7px 2px 7px !important;
  color: indianred;
  float: right;

  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  background-color: transparent;
  border: 1px solid grey;
  line-height: 1.5;
  border-radius: .25rem;
}

.card-redeemed .badge {
  -webkit-filter: grayscale(1);
}

.card-expired .badge {
  -webkit-filter: grayscale(1);
}

.card-expired img {
  -webkit-filter: grayscale(1);
}

.account-box {
  padding: 0 35px;
  border: 1px solid #ececec;
  border-radius: 10px;
}

.clipboard {
  font-size: small;
  height: 25px;
  padding: 2px 7px 0 7px;
  border-radius: 0 5px 5px 0;
  background: #3374F6;
  width: 35px;
}

.clipboard-msg {
  transition: opacity 1s ease;
  opacity: 0;
  font-size: 11px;
  position: absolute;
  top: 3px;
  font-weight: bolder;
  text-align: center;
  height: 19px;
  left: 40px;
  color: white;
  width: 55px;
  background: #3374F6;
  border: 1px solid #3374F6;
  border-radius: 5px;
  z-index: 990;
}

.clipboard-arr {
  font-size: 14px;
  position: absolute;
  top: 0px;
  height: 17px;
  left: -27px;
  color: #3374F6;
  width: 60px;
  z-index: 990;
  transform: rotate(270deg);
}


.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  font-size: 13px;
  border: 1px solid transparent;
  border-radius: .25rem;
}
@keyframes load {
  0% {
    left: 0;
    width: 0px;
  }
  50% {
    left: 150px;
    width: 150px;
  }
  100% {
    left: 350px;
    width: 0px;
  }
}

#loader-wrapper {
  display: flex;
  /*justify-content: center;*/
  padding-top: 30vh;
  align-items: center;
  flex-direction: column;
  background-color: white;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  zoom: calc(1/.95);
  z-index: 2;
  opacity: 1;
  transition: opacity .4s;
}

#loader-wrapper .loader {
  margin-top: 30px;
  position: relative;
  width: 300px;
  min-height: 8px;
  border-radius: 3px;
  overflow: hidden;
}

#loader-wrapper .loader::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 300px;
  min-height: 8px;
  background-color: #ededed;
}

#loader-wrapper .loader::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 30px;
  min-height: 8px;
  background-color: red;
  animation: load 2.5s linear infinite;
}